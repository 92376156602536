import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import * as R from 'runtypes';
import { Static } from 'runtypes';

import { URLS } from './app.constant';
import { SuccessOnlyResponse } from '../utils/utils';

export const USA_ISO3_STRING = 'USA';

const Country = R.Record({
  countryId: R.Number,
  name: R.String,
  isoCode3: R.String,
  phoneCode: R.String,
});
export type Country = Static<typeof Country>;

const CountriesResponse = SuccessOnlyResponse(R.Array(Country));

const State = R.Record({ id: R.Number, stateName: R.String });
export type State = Static<typeof State>;

const StatesResponse = SuccessOnlyResponse(R.Array(State));

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(private readonly http: HttpClient) {}

  getCountryList(): Observable<SuccessOnlyResponse<Array<Country>>> {
    return this.http.get(URLS.getCountryList).pipe(map(CountriesResponse.check));
  }

  getStateList(countryId: string): Observable<SuccessOnlyResponse<Array<State>>> {
    return this.http
      .get(URLS.countryStateListUrl.replace(':countryId', countryId), {
        params: {
          status: 'true',
        },
      })
      .pipe(map(StatesResponse.check));
  }
}
