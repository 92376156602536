import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as R from 'runtypes';
import { Static } from 'runtypes';
import { BigNumber } from 'bignumber.js';

import { Currency } from '../entities/currency';
import { URLS } from './app.constant';
import { MarketService } from './market.service';
import { defined, Enum, KeyOf } from '../utils/runtypes';
import { ZERO } from '../utils/number';
import { ChilledWallet } from '../entities/chilled-vault';

export enum LedgerType {
  TRADING = 'TRADING',
  EARNING = 'EARNING',
  INTEREST = 'INTEREST',
  COLLATERAL = 'COLLATERAL',
  REVENUE = 'REVENUE',
  STAKING = 'STAKING',
}

const ApiLedger = R.Record({
  id: R.Number,

  currencyCd: R.String,
  ledgerTypeEn: Enum(LedgerType),

  totalBalanceAmt: R.String,
  reservedBalanceAmt: R.String,

  estimatedUsdBalanceAmt: R.String.nullable(),
  estimatedReservedBalanceAmt: R.String.nullable(),
});
export type ApiLedger = Static<typeof ApiLedger>;

export interface CustomerLedger {
  readonly currency: Currency;

  readonly balance: BigNumber;
  readonly reservedBalance: BigNumber;

  readonly balanceUsd: BigNumber;
  readonly reservedBalanceUsd: BigNumber;

  readonly availableBalance: BigNumber;
  readonly availableUsdBalance: BigNumber;
}

export class Ledger implements CustomerLedger {
  static buildZeroLedger(type: LedgerType, currency: Currency): Ledger {
    return new Ledger(type, currency, ZERO, ZERO, ZERO, ZERO);
  }

  static build(rl: Omit<ApiLedger, 'currencyCd' | 'id'>, currency: Currency) {
    return new Ledger(
      rl.ledgerTypeEn,
      currency,
      BigNumber(rl.totalBalanceAmt),
      BigNumber(rl.reservedBalanceAmt),
      BigNumber(rl.estimatedUsdBalanceAmt ?? 0),
      BigNumber(rl.estimatedReservedBalanceAmt ?? 0)
    );
  }

  constructor(
    readonly ledgerType: LedgerType,
    readonly currency: Currency,
    readonly balance: BigNumber,
    readonly reservedBalance: BigNumber,
    readonly balanceUsd: BigNumber,
    readonly reservedBalanceUsd: BigNumber
  ) {}

  get displayName(): string {
    return `${this.currency.id} (${this.ledgerType})`;
  }

  get availableBalance(): BigNumber {
    return this.balance.plus(this.reservedBalance);
  }

  get availableUsdBalance(): BigNumber {
    if (this.currency.id == Currency.USD) {
      return this.availableBalance;
    }
    return this.balanceUsd.plus(this.reservedBalanceUsd);
  }

  get inCredit(): boolean {
    return this.availableBalance.gt(0);
  }
}

export class CombinedLedger implements CustomerLedger {
  private ledgers: Array<Ledger>;
  private interestEarningLedgers: Array<Ledger>;

  tradeLedger: Ledger;
  collateralLedger: Ledger;
  private _earnLedger: Ledger;
  private _stakingLedger: Ledger;
  chilledWallet?: ChilledWallet;

  constructor(public readonly currency: Currency, potentialLedgers: Array<Ledger>, chilledWallet?: ChilledWallet) {
    this.ledgers = potentialLedgers;
    this.chilledWallet = chilledWallet;

    // If more types of ledger appear it might be worth turning this into a Map
    this.tradeLedger = this.ledgerFor(LedgerType.TRADING, this.currency);
    this._earnLedger = this.ledgerFor(LedgerType.EARNING, this.currency);
    this._stakingLedger = this.ledgerFor(LedgerType.STAKING, this.currency);
    this.collateralLedger = this.ledgerFor(LedgerType.COLLATERAL, this.currency);

    /**
     * There will normally only be one interest earning ledger per currency. However, if a customer's
     * accreditation status has changed there could be both.
     */
    this.interestEarningLedgers = [this._earnLedger, this._stakingLedger];
  }

  private ledgerFor(type: LedgerType, currency: Currency) {
    return this.ledgers.find((l) => l.ledgerType === type) || Ledger.buildZeroLedger(type, currency);
  }

  get balance(): BigNumber {
    let result = this.aggregate('balance');
    if (this.chilledWallet) {
      result = result.plus(this.chilledWallet.available);
    }

    return result;
  }

  get balanceUsd(): BigNumber {
    let result = this.aggregate('balanceUsd');
    if (this.chilledWallet?.availableUsd) {
      result = result.plus(this.chilledWallet.availableUsd);
    }

    return result;
  }

  get reservedBalance(): BigNumber {
    return this.aggregate('reservedBalance');
  }

  get reservedBalanceUsd(): BigNumber {
    return this.aggregate('reservedBalanceUsd');
  }

  get availableBalance(): BigNumber {
    return this.aggregate('availableBalance');
  }

  get availableUsdBalance(): BigNumber {
    return this.aggregate('availableUsdBalance');
  }

  get interestEarningBalance(): BigNumber {
    return this.aggregate('balance', this.interestEarningLedgers);
  }

  get interestEarningBalancUsd(): BigNumber {
    return this.aggregate('balanceUsd', this.interestEarningLedgers);
  }

  private aggregate(properyName: KeyOf<CustomerLedger, BigNumber>, ledgers = this.ledgers): BigNumber {
    return ledgers
      .map((ledger) => ledger[properyName]) //
      .reduce((total, v) => total.plus(v), ZERO);
  }
}

@Injectable({
  providedIn: 'root',
})
export class LedgerService {
  constructor(private readonly http: HttpClient, private readonly marketService: MarketService) {}

  public getLedgers(types: Array<LedgerType>): Observable<Array<Ledger>> {
    return combineLatest([
      this.http.get(URLS.ledgerList).pipe(
        map(R.Array(ApiLedger).check),
        map((ledgers) => ledgers.filter((rl) => types.includes(rl.ledgerTypeEn)))
      ),
      this.marketService.currencies,
    ]).pipe(map(makeLedgers));
  }

  public spendBalances(): Observable<Array<Ledger>> {
    return combineLatest([
      this.http.get(URLS.spendBalances).pipe(map(R.Array(ApiLedger).check)), //
      this.marketService.currencies,
    ]).pipe(map(makeLedgers));
  }
}

const makeLedgers = ([rawLedgers, currencies]: [Array<ApiLedger>, Array<Currency>]) => {
  const currenciesById = new Map<string, Currency>(currencies.map((c) => [c.id, c]));
  return (
    rawLedgers
      .map((rl) => [rl, currenciesById.get(rl.currencyCd)] as const)
      // exclude any ledgers which use currencies that aren't available
      .filter((d): d is [ApiLedger, Currency] => {
        const [_, currency] = d;
        return defined(currency);
      })
      .map(([rl, currency]) => Ledger.build(rl, currency))
  );
};
