import { Injectable } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';

import { OrderExecutionNotification, ResourceResponse, LoginSuccess, AuthService } from 'core';
import { Order } from 'entities/order';

export enum Theme {
  night = 'themeNight',
  day = 'themday',
}

@Injectable({
  providedIn: 'root',
})
export class BroadcastService {
  logoutSource$ = this.authService.$loggedIn;

  private enableAccountSource = new Subject<void>();
  enableAccountSource$ = this.enableAccountSource.asObservable();

  private themeChangeSource = new ReplaySubject<Theme>(1);
  themeChangeSource$ = this.themeChangeSource.asObservable();

  private orderChangeSource = new Subject<OrderExecutionNotification>();
  orderChangeSource$ = this.orderChangeSource.asObservable();

  private loginAfterVerificationSource = new ReplaySubject<ResourceResponse<LoginSuccess>>();
  loginAfterVerificationSource$ = this.loginAfterVerificationSource.asObservable();

  // TODO refresh UI when KYC status changes using authService.reloadUserData?
  private kycChangeSource = new Subject<void>();
  kycChangeSource$ = this.kycChangeSource.asObservable();

  private accountBlockedSource = new Subject<void>();
  accountBlockedSource$ = this.accountBlockedSource.asObservable();

  closedOrderSource = new Subject<Order>();
  $closedOrders = this.closedOrderSource.asObservable();

  constructor(private readonly authService: AuthService) {}

  broadcastOrderChange(event: OrderExecutionNotification): void {
    this.orderChangeSource.next(event);
  }

  broadcastLogoutChange(loggedIn: boolean): void {
    this.authService.publishLoggedInStatus(loggedIn);
  }

  broadcastEnableAccountChange(): void {
    this.enableAccountSource.next();
  }

  broadcastThemeChange(theme: Theme) {
    this.themeChangeSource.next(theme);
  }

  broadcastLoginAfterVerification(loginResponse: ResourceResponse<LoginSuccess>) {
    this.loginAfterVerificationSource.next(loginResponse);
  }

  broadcastKycStatusChange() {
    this.kycChangeSource.next();
  }

  broadcastAccountBlockedChange() {
    this.accountBlockedSource.next();
  }

  closedOrder(order: Order) {
    this.closedOrderSource.next(order);
  }
}
